import React from "react"
import styles from "./banner.module.scss"
import { PageContext } from "../../../page-context"
import { openOverlay } from "../../../../utils/overlay"

const BannerComponent = ({ children, color, author, ctaText }) => (
  <div className={`${styles.banner} ${color === "green" ? styles.green : ""}`}>
    <div className={`${styles.text} ${author ? styles.withAuthor : ""}`}>{children}</div>
    <div className={styles.author}>{author}</div>
    {(!author || ctaText) && <div>
      <PageContext.Consumer>
        {page => (
          <button className={styles.cta}
                  onClick={() => openOverlay(page, "banner", "try")}>{ctaText || "Попробовать бесплатно"}
          </button>)
        }
      </PageContext.Consumer>
    </div>}
  </div>
)

export default BannerComponent
